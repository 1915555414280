
import { Vue, Options } from "vue-class-component";
import UserController from "@/AppClubCarby/Controller/UserController";
import Swal from "sweetalert2";
import GasStationController from "@/AppClubCarby/Controller/GasStationController";
import { GasStationInterface } from "@/models/GasStationModel";
import Loading from "vue3-loading-overlay";
import ViaCepService from "@/services/ViaCepService";
import NameInputComponent from "@/AppClubCarby/Components/NameInputComponent/NameInputComponent.vue";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";
import { cpfRemoveMask } from "@/mixins";

@Options({
  data() {
    return {
      v$: useVuelidate(),
      userController: new UserController(),
      viaCepService: new ViaCepService(),
      gasStationController: new GasStationController(),
      selectedGasStation: {
        id: null,
      },
      gasStations: [],
      isLoading: false,
      passwordVerify: null,
      userData: {
        attendantCode: null,
        id: null,
        user: {
          type: "ATTENDANT",
          id: null,
          birthDate: null,
          cpf: null,
          email: null,
          gender: null,
          name: null,
          nationality: null,
          password: null,
          phone: null,
          address: {
            cep: null,
            city: null,
            country: null,
            district: null,
            latitude: null,
            longitude: null,
            number: null,
            street: null,
            surname: null,
            uf: null,
            complement: null,
          },
        },
        gasStation: {
          id: "oi",
        },
      },
    };
  },
  components: {
    Loading,
    NameInputComponent,
  },
  created() {
    this.getGasStations();
  },
  validations() {
    return {
      userData: {
        user: {
          name: { required },
          cpf: { required, minLength: minLength(11) },
        },
      },
    };
  },
  methods: {
    getGasStations() {
      this.gasStationController
        ._getGasStation()
        .then((item: GasStationInterface) => {
          this.gasStations = item.data.content;
          console.log(this.gasStations);
        });
    },
    selectGasStation(e: any) {
      this.gasStations.forEach((gasStation: any) => {
        if (gasStation.id == e.target.value) {
          this.selectedGasStation = gasStation.id;

          console.log(this.userData);
        }
      });
    },
    selectCep() {
      if (this.userData.user.address.cep.length == 8) {
        this.isLoading = true;
        this.viaCepService
          ._getCep(this.userData.user.address.cep)
          .then((address: any) => {
            this.userData.user.address.city = address.data.city;
            this.userData.user.address.uf = address.data.state;
            this.userData.user.address.street = address.data.street;
            this.userData.user.address.district = address.data.neighborhood;
            this.userData.user.address.surname = `Endereço ${this.userData.user.name}`;
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            Swal.fire({
              customClass: {
                actions: "modal-register-actions",
                confirmButton: "modal-btn modal-btn-confirm",
                icon: "modal-register-icon",
                title: "modal-register-title",
                container: "modal-register error",
                closeButton: "modal-register-close",
              },
              title: "Ooops!",
              text: "CEP não encontrado, favor digitar novamente.",
              icon: "error",
              confirmButtonText: "Ok",
            });
          });
      }
    },
    sendForm() {
      this.v$.$validate();

      console.log(!this.v$.$error);
      console.log(this.v$);

      if (!this.v$.$error) {
        if (this.passwordVerify !== this.userData.user.password) {
          Swal.fire({
            customClass: {
            actions: "modal-register-actions",
            confirmButton: "modal-btn modal-btn-confirm",
            icon: "modal-register-icon",
            title: "modal-register-title",
            container: "modal-register error",
            closeButton: "modal-register-close",
          },
            title: "Oops!",
            text: "As senhas não coincidem, por favor verifique!",
            icon: "error",
            confirmButtonText: "Ok",
          });
          return false;
        }
        this.isLoading = true;
        const userNameSplitted = this.userData.user.name.split(" ");
        this.userData.user.email = `${userNameSplitted[0].toLowerCase()}.${userNameSplitted[
          userNameSplitted.length - 1
        ].toLowerCase()}@teste.com.br`;

        this.userData.user.address.surname = `${userNameSplitted[0].toLowerCase()}.${userNameSplitted[
          userNameSplitted.length - 1
        ].toLowerCase()} Address Surname`;

        console.log("[code]", this.userData);
        this.userData.user.cpf = cpfRemoveMask(this.userData.user.cpf);
        this.userController
          .setUser(this.userData.user.type, this.userData)
          .then(() => {
            this.isLoading = false;
            Swal.fire({
              customClass: {
                actions: "modal-register-actions",
                confirmButton: "modal-btn modal-btn-confirm",
                icon: "modal-register-icon",
                title: "modal-register-title",
                container: "modal-register success",
                closeButton: "modal-register-close",
              },
              title: "Yay!",
              text: "Usuário cadastrado com sucesso!",
              icon: "success",
              confirmButtonText: "Ok",
            });
            this.userData.gasStation.id = null;
            this.userData.user.name = null;
            this.userData.user.cpf = null;
            this.userData.user.address.cep = null;
            this.userData.user.address.street = null;
            this.userData.user.address.complement = null;
            this.userData.user.address.district = null;
            this.userData.user.address.city = null;
            this.userData.user.address.uf = null;
            this.userData.user.address.number = null;
            this.userData.user.email = null;
            this.userData.user.password = null;
            this.passwordVerify = null;
            this.userData.attendantCode = null;
          })
          .catch((error) => {
            this.isLoading = false;

            Swal.fire({
              customClass: {
                actions: "modal-register-actions",
                confirmButton: "modal-btn modal-btn-confirm",
                icon: "modal-register-icon",
                title: "modal-register-title",
                container: "modal-register error",
                closeButton: "modal-register-close",
              },
              title: "Oops!",
              text: "Algum erro inesperado aconteceu, verifique todos os campos e tente novamente.",
              icon: "error",
              confirmButtonText: "Ok",
            });
          });
      }
    },
  },
})
export default class AppUsersAttendantRegister extends Vue { }
