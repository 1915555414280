
import { Vue, Options } from "vue-class-component";
import UserController from "@/AppClubCarby/Controller/UserController";
import ModalComponent from "@/LandingPage/Components/ModalComponent/ModalComponent.vue";
import { ClientDashboardController } from "@/AppClubCarby/Controller/DashBoardsController";
import CardComponent from "@/AppClubCarby/Components/CardComponent/CardComponent.vue";
import GasStationController from "@/AppClubCarby/Controller/GasStationController";
import { formatNumber, getMonth, formatCurrencyWithoutSymbol } from "@/mixins";
import loading from "vue3-loading-overlay";

@Options({
  beforeRouteEnter(to, from, next) {
    let role = localStorage.getItem("ROLE");
    if (role === "ROLE_CLIENT") {
      next();
    } else {
      next("/not-found");
    }
  },
  created() {
    this.isLoading = true;
    this.selectYear();
    this.userController.getOneUserByCPF().then((res) => {
      this.user = res;
      if (res.loyalUser) {
        this.modalActive = true;
      }
      this.getNetworkList();

      this.getChartValue();

      this.checkValueLength(this.user.remainingEmissions) >= 7
        ? (this.remainingEmissionBigNumber = true)
        : "";
      this.checkValueLength(this.user.carbonOffsetAutomatically) >= 7
        ? (this.offsetBigNumber = true)
        : "";
      this.checkValueLength(this.user.emittedCarbon) >= 7
        ? (this.emissionBigNumber = true)
        : "";

      this.getTotalSupllyByClient();

      this.isLoading = false;

      this.selectElementNetwork = document.querySelector(".selector-network");
      this.selectElementYear = document.querySelector(".selector-year");
    });
  },
  components: {
    CardComponent,
    loading,
    ModalComponent,
  },
  data() {
    return {
      isLoading: false,
      networkLoading: false,
      loadingTransactions: false,
      setAnimationArrow: false,
      setAnimationArrowYear: false,
      userController: new UserController(),
      clientDashboardController: new ClientDashboardController(),
      gasStationService: new GasStationController(),
      networks: [],
      selectedNetwork: null,
      cashback: 0,
      selectedYear: null,
      openEmailModal: null,
      verifiedOnce: false,
      remainingEmissionBigNumber: false,
      emissionBigNumber: false,
      offsetBigNumber: false,
      years: [],
      seriesToChange: [],
      user: {
        remainingEmissions: 0,
        carbonOffsetAutomatically: 0,
        emittedCarbon: 0,
      },
      supplies: 0,
      levelsChartInfo: [
        {
          level: 1,
          treeName: "Palmeira",
          valueMark: 50,
          urlImage: "medals-level-1",
        },
        {
          level: 2,
          treeName: "Ipê",
          valueMark: 200,
          urlImage: "medals-level-2",
        },
        {
          level: 3,
          treeName: "Jacarandá",
          valueMark: 600,
          urlImage: "medals-level-3",
        },
      ],
      levelCarbonOffset: {
        level: 1,
        treeName: "",
        valueMark: 2,
        urlImage: "medals-level-1",
      },
      chartOptions: {
        chart: {
          type: "area",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          categories: [],
        },
      },
      series: [],
      seriesProgress: [
        {
          name: "Process 1",
          data: [],
        },
      ],
      chartOptionsProgress: {
        chart: {
          height: 10,
          type: "bar",
          stacked: true,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 6,
            barHeight: "100%",
            colors: {
              backgroundBarColors: ["#E3E3E1"],
              backgroundBarRadius: 6,
            },
          },
        },
        colors: ["#11592e"],

        stroke: {
          width: 0,
        },
        tooltip: {
          enabled: false,
        },
        xaxis: {
          categories: ["Process 1"],
        },
        yaxis: {
          max: 100,
        },
        fill: {
          opacity: 1,
        },
      },
      modalActive: false,
    };
  },
  updated() {
    this.verifyEmail();
  },
  watch: {
    seriesToChange(value, oldValue) {
      if (value) {
        this.updateChart();
      }
    },
    selectedYear(value) {
      if (value) {
        this.getChartValue();
      }
    },

    selectedNetwork(newValue, oldValue) {
      this.networkLoading = true;
      this.getCashBackNetwork();
    },
  },
  methods: {
    replace(data: number) {
      if (!NaN) {
        return formatNumber(data);
      }
    },
    updateChart() {
      let series: any = [];
      let emmitedCarbon: any = [];
      let offsetCarbon: any = [];
      this.seriesToChange.forEach((e: any) => {
        emmitedCarbon.push(e.emmitedCarbon);
        offsetCarbon.push(e.offsetCarbon);
        series.push(getMonth("short", e.yearMonth[1]));
      });
      this.chartOptions = {
        xaxis: {
          categories: series,
        },
      };
      this.series = [
        {
          name: "Emissão Kg CO2e",
          data: emmitedCarbon,
          color: "#11592E",
        },
        {
          name: "Compensação Kg CO2e",
          data: offsetCarbon,
          color: "#7DED84",
        },
      ];
    },
    getCashBackNetwork() {
      this.loadingTransactions = true;
      this.clientDashboardController
        .getTransactionsByNetwork(this.user.id, this.selectedNetwork.id)
        .then((data) => {
          this.cashback = data.data.amountCashbackValue;
          this.isLoading = false;
          this.networkLoading = false;
          this.loadingTransactions = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.loadingTransactions = false;
        });
    },

    async getTotalSupllyByClient() {
      this.isLoading = true;
      await this.clientDashboardController
        .getFuelTransactionTotalChartData(this.user.id)
        .then((data) => {
          this.supplies = data.data;
          this.getLevelcarbonOffset(this.supplies);
          this.seriesProgress = [
            {
              data: [
                Math.trunc(
                  (this.supplies / this.levelCarbonOffset.valueMark) * 100
                ),
              ],
            },
          ];
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    getChartValue() {
      if (this.user.id && this.selectedYear) {
        this.clientDashboardController
          ._getAmountByMonth(this.user.id, this.selectedYear)
          .then(async (data: any) => {
            this.seriesToChange = data.data;
          });
      }
    },
    getImgUrl(img) {
      var images = require.context("@/assets", false, /\.png$/);
      return images("./" + img + ".png");
    },
    getLevelcarbonOffset(value: number) {
      let level;
      if (value <= 50) {
        level = 1;
      } else if (value > 50 && value <= 200) {
        level = 2;
      } else if (value > 200) {
        level = 3;
      }
      this.levelCarbonOffset = this.levelsChartInfo.find(
        (item) => item.level === level
      );
    },
    async getNetworkList() {
      this.isLoading = true;
      await this.gasStationService
        .getGasStationNetworks(0)
        .then((data) => {
          this.networks = data.data.content;
          this.selectedNetwork = data.data.content[0];
        })
        .catch((error) => {
          this.isLoading = false;
        });

      this.getCashBackNetwork();
    },
    openModal() {
      this.modalActive = !this.modalActive;
    },
    inviteModal() {
      this.openModal();
      this.inviteModalActive = !this.inviteModalActive;
    },
    closeInviteModal() {
      this.inviteModalActive = !this.inviteModalActive;
    },
    selectYear() {
      let date = new Date();
      let dateArr = date.toString().split(" ");
      let year = dateArr[3];
      let actualYearNumber = parseInt(year);
      this.selectedYear = actualYearNumber;

      for (let i = 2022; actualYearNumber >= i; i++) {
        this.years.push(i);
      }
    },
    checkValueLength(value) {
      let str = value.toString();
      return str.length;
    },
    verifyEmail() {
      if (this.$store.state.validEmail !== null && !this.verifiedOnce) {
        this.verifiedOnce = true;
        this.openEmailModal = !this.$store.state.validEmail;
      }
    },
    closeModal() {
      this.openEmailModal = false;
    },
  },
})
export default class CarbonFootprint extends Vue {}
